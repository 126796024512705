




























































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { getAsBase64, photoService } from "@/services/photo.service";
import { photoMapper } from "@/mappers/photo.mapper";
import { ImageData } from "@/models/ImageData";
import { Category } from "@/models/Category";
import { VisualTask } from "@/models/VisualTask";
import { taskService } from "@/services/task.service";
import { taskMapper } from "@/mappers/task.mapper";
import { annotationService } from "@/services/annotation.service";
import ImageAnnotations from "@/components/ImageAnnotations.vue";
import { AnnotationType } from "@/models/enums/AnnotationType";
import { colors } from "@/shared/constants";

@Component({
  components: {
    ImageAnnotations,
    Thumbnails: () => import("@/components/Thumbnails.vue")
  }
})
export default class Annotate extends Vue {
  imageData = new ImageData([], [], 0, new window.Image(), 1);
  thumbnailsHeightPrefixSum: number[] = [];
  next = "next";

  categoryVisibility = [];
  task = new VisualTask(0, "", "", 0, []);
  taskId = 0;
  photoCollectionId = 0;
  currentCategory = new Category(0, "", "", AnnotationType.NoType);
  hotkeyDict = { left: ["arrowleft"], right: ["arrowright"], a: ["a"] };
  infoVisible = false;
  instructions = [
    "To zoom in/out -> ctrl + mousewheel",
    "Two modes available: Normal and Move",
    "Normal mode allows you to annotate images",
    "Move mode allows you to move on zoomed-in images",
    "You can change annotation categories with number keys (1-9)"
  ];
  comment = "";
  loading = false;
  loadingComment = false;
  loadingImage = false;
  isBad = false;

  get polygonCategoryIds() {
    return this.task.categories
      .filter(category => category.annotationType == AnnotationType.Polygons)
      .map(category => category.id);
  }

  get rectangleCategoryIds() {
    return this.task.categories
      .filter(category => category.annotationType == AnnotationType.Rectangles)
      .map(category => category.id);
  }

  get isLoading() {
    return this.loading || this.loadingComment || this.loadingImage;
  }

  async created() {
    this.getDataFromRouteQuery();
    await this.getTaskWithCategories();
    this.getPhotosForPhotoCollection(this.photoCollectionId);
    this.getThumbnailsForPhotoCollection(this.photoCollectionId);

    // set up categories hotkeys
    for (let i = 1; i <= 9; i++) {
      this.hotkeyDict = { ...this.hotkeyDict, ...{ [String(i)]: [String(i)] } };
    }
  }

  mounted() {
    let annotationComponent: any = this.$refs.annotationComponent;
    console.log(annotationComponent);
  }

  private isValidURL(str: string) {
    let a = document.createElement("a");
    a.href = str;
    return a.host && a.host != window.location.host;
  }

  private getDataFromRouteQuery() {
    this.taskId = Number(this.$route.query.taskId as string);
    this.photoCollectionId = Number(
      this.$route.query.photoCollectionId as string
    );
  }

  private async getTaskWithCategories() {
    await taskService
      .getVisualTaskWithCategories(this.taskId)
      .then(response => {
        this.task = taskMapper.mapVisualTaskWithCategories(response.data);
        for (let category of this.task.categories) {
          Vue.set(this.categoryVisibility, category.id, true);
        }
      });
  }

  private getPhotosForPhotoCollection(photoCollectionId: number) {
    photoService
      .getPhotosForPhotoCollection(photoCollectionId)
      .then(response => {
        this.imageData.images = photoMapper.mapPhotos(response.data);
        this.setImage();
      });
  }

  private getThumbnailsForPhotoCollection(photoCollectionId: number) {
    photoService
      .getThumbnailsForPhotoCollection(photoCollectionId)
      .then(async response => {
        const thumbnailPhotos = photoMapper.mapPhotos(response.data);
        Vue.set(this.imageData, "thumbnailsData", thumbnailPhotos);
        photoService
          .getThumbnailsHeightPrefixSum(photoCollectionId)
          .then(response => {
            this.thumbnailsHeightPrefixSum = response.data;
          });
      });
  }

  save() {
    let annotationComponent: any = this.$refs.annotationComponent;
    return annotationComponent.save();
  }

  saveCommentAndIsBad() {
    return annotationService.updateVisualAnnotationGroup(
      this.task.id,
      this.imageData.images[this.imageData.currentImageIndex].id,
      this.comment,
      this.isBad
    );
  }

  saveStart() {
    this.loading = true;
  }

  saveEnd() {
    this.saveCommentAndIsBad().finally(() => {
      this.loading = false;
    });
  }

  previousImage(): void {
    if (this.isLoading) {
      return;
    }
    this.comment = "";
    this.isBad = false;
    if (this.imageData.currentImageIndex > 0) {
      this.imageData.currentImageIndex--;
      this.setImage();
    }
    if (this.next === "end") {
      this.next = "next";
    }
  }

  changeButtonNames(): void {
    if (this.imageData.currentImageIndex >= this.imageData.images.length - 2) {
      this.next = "end";
    }
  }

  nextImage(): void {
    if (this.isLoading) {
      return;
    }
    this.comment = "";
    this.changeButtonNames();
    if (this.imageData.currentImageIndex + 1 < this.imageData.images.length) {
      this.imageData.currentImageIndex++;
      this.setImage();
    } else {
      this.save();
      window.location.href = "/tasks";
    }
  }

  nextImageSave(): void {
    if (this.isLoading) {
      return;
    }
    this.loading = true;
    this.changeButtonNames();
    if (this.imageData.currentImageIndex + 1 < this.imageData.images.length) {
      this.save()
        .then(() => {
          this.saveCommentAndIsBad()
            .then(() => {
              this.imageData.currentImageIndex++;
              this.setImage();
              this.comment = "";
              this.isBad = false;
            })
            .finally(() => {
              this.loading = false;
            });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  setCommentAndIsBad() {
    this.loadingComment = true;
    annotationService
      .getVisualAnnotationGroup(
        this.task.id,
        this.imageData.images[this.imageData.currentImageIndex].id
      )
      .then(response => {
        this.comment = response.data["comment"];
        this.isBad = response.data["is_bad"];
      })
      .catch(() => {
        this.comment = "";
      })
      .finally(() => {
        this.loadingComment = false;
      });
  }

  setImage() {
    this.setCommentAndIsBad();
    this.currentCategory = this.task.categories[0];

    const img = new window.Image();
    const base64 = getAsBase64(
      this.imageData.images[this.imageData.currentImageIndex].imageSrc
    );

    this.loadingImage = true;
    base64.then(base64Src => {
      if (typeof base64Src === "string") {
        img.src = base64Src;

        img.onload = () => {
          // width adequately to the number of columns
          const maxWidth = (window.innerWidth * 7) / 12;
          const maxHeight = window.innerHeight - 4 * 60;

          const scale = Math.min(maxWidth / img.width, maxHeight / img.height);
          img.height *= scale;
          img.width *= scale;

          this.imageData.currentWindowImage = img;
          this.imageData.imageScale = scale;
          this.loadingImage = false;
        };
      }
    });
  }

  handleThumbnailClick(index: number) {
    if (
      index >= 0 &&
      index < this.imageData.images.length &&
      index != this.imageData.currentImageIndex &&
      !this.isLoading
    ) {
      this.imageData.currentImageIndex = index;
      this.setImage();
    }
  }

  selectCategory(category: Category) {
    this.currentCategory = category;
  }

  getColor(category: Category) {
    let index = 0;
    if (category.annotationType == AnnotationType.Rectangles) {
      index = this.rectangleCategoryIds.indexOf(category.id);
    } else {
      index =
        this.rectangleCategoryIds.length +
        this.polygonCategoryIds.indexOf(category.id);
    }
    return colors[index];
  }

  toggleCategoryVisibility(category: Category) {
    const value: any = !this.categoryVisibility[category.id];
    Vue.set(this.categoryVisibility, category.id, value);
  }

  hotkeyHandler(event: any) {
    switch (event.srcKey) {
      case "left":
        this.previousImage();
        break;
      case "right":
        this.nextImage();
        break;
      case "a":
        this.nextImageSave();
        break;
    }
    if (event.srcKey >= "1" && event.srcKey <= "9") {
      const index = event.srcKey - Number("0") - 1;
      if (index >= 0 && index < this.task.categories.length) {
        this.selectCategory(this.task.categories[index]);
      }
    }
  }
}
