import { Point } from "@/models/Point";
import { AnnotationWithPointsEntity } from "@/entities/AnnotationWithPoints.entity";
import { AnnotationPointEntity } from "@/entities/AnnotationPoint.entity";
import { Annotation } from "@/models/Annotation";
import { AnnotationType } from "@/models/enums/AnnotationType";

const ANCHOR_INNER_RADIUS = 4;
const ANCHOR_OUTER_RADIUS = 15;
const ANCHOR_ACTIVATED_RADIUS = 5;
const ANCHOR_COLOR = "white";
const ANCHOR_ACTIVATED_COLOR = "red";

export interface Anchor {
  id: number;
  x: number;
  y: number;
  innerRadius: number;
  outerRadius: number;
  stroke: string;
  color: string;
  categoryId: number;
}

export interface PointSet {
  first: Point;
  second: Point;
}

export interface Edge {
  id: number;
  pointSet: PointSet;
  categoryId: number;
}

export enum ActionType {
  AddAnchor,
  DragAnchor,
  CreateMiddlePoint
}

export interface History {
  actionType: ActionType;
  anchorIndex: number;
  edgeIndex: number;
  edge: Edge;
  x: number;
  y: number;
  polygonIndex: number;
}

export class SinglePolygonAnnotationData {
  private readonly _anchors: Array<Anchor>;
  private readonly _edges: Array<Edge>;
  private _isPolygonFinished: boolean;
  private _id: number;

  constructor(id: number) {
    this._anchors = Array<Anchor>();
    this._edges = Array<Edge>();
    this._isPolygonFinished = false;
    this._id = id;
  }

  // getters and setters
  public get anchors(): Array<Anchor> {
    return this._anchors;
  }

  public get edges(): Array<Edge> {
    return this._edges;
  }

  public get isPolygonFinished(): boolean {
    return this._isPolygonFinished;
  }

  public set isPolygonFinished(value: boolean) {
    this._isPolygonFinished = value;
  }

  public get id(): number {
    return this._id;
  }

  public set id(newId: number) {
    this._id = newId;
  }

  // we determine the category id form the first anchor
  // if there are no anchors we return -1 to mark that this polygon is empty
  public get categoryId(): number {
    if (this._anchors.length == 0) {
      return -1;
    }
    return this._anchors[0].categoryId;
  }

  // anchors
  public anchorSetDefaults(anchor: any): Anchor {
    anchor.innerRadius = ANCHOR_INNER_RADIUS;
    anchor.outerRadius = ANCHOR_OUTER_RADIUS;
    anchor.color = ANCHOR_COLOR;
    return anchor;
  }

  public addAnchor(anchor: any) {
    this._anchors.push(this.anchorSetDefaults(anchor));
  }

  public activateAnchor(anchor: Anchor) {
    anchor.innerRadius = ANCHOR_ACTIVATED_RADIUS;
    anchor.color = ANCHOR_ACTIVATED_COLOR;
  }

  public deactivateAnchor(anchor: Anchor) {
    anchor.innerRadius = ANCHOR_INNER_RADIUS;
    anchor.color = ANCHOR_COLOR;
  }

  public emptyAnchors(): boolean {
    return this._anchors.length === 0;
  }

  public anchorsSize(): number {
    return this._anchors.length;
  }

  // edges
  public addEdge(edge: Edge) {
    this._edges.push(edge);
  }

  public emptyEdges(): boolean {
    return this._edges.length === 0;
  }

  public edgesSize(): number {
    return this._edges.length;
  }

  public clear() {
    this._isPolygonFinished = false;

    while (!this.emptyAnchors()) {
      this._anchors.pop();
    }

    while (!this.emptyEdges()) {
      this._edges.pop();
    }
  }

  public mapAnchorsToPoints() {
    return this._anchors.map(anchor => {
      return new Point(anchor.x, anchor.y);
    });
  }
}

export class MultiPolygonAnnotationData {
  private readonly _polygons: Array<SinglePolygonAnnotationData>;
  private readonly _history: Array<History>;
  private _nextKey: number;

  constructor() {
    this._polygons = new Array<SinglePolygonAnnotationData>();
    this._history = new Array<History>();
    this.addPolygon();
    this._nextKey = 1;
  }

  private addPolygon() {
    // same as in rectangles - polygons that are not saved yet have negative ids
    this._polygons.push(
      new SinglePolygonAnnotationData(-this._polygons.length - 1)
    );
  }

  // getters and setters
  public get polygons(): Array<SinglePolygonAnnotationData> {
    return this._polygons;
  }

  public get anchors(): Array<Anchor> {
    return this._polygons[this._polygons.length - 1].anchors;
  }

  public get edges(): Array<Edge> {
    return this._polygons[this._polygons.length - 1].edges;
  }

  public get history(): Array<History> {
    return this._history;
  }

  public get isPolygonFinished(): boolean {
    return this._polygons[this._polygons.length - 1].isPolygonFinished;
  }

  public set isPolygonFinished(value: boolean) {
    this._polygons[this._polygons.length - 1].isPolygonFinished = value;
  }

  // nextKey
  public get nextKey(): number {
    return this._nextKey;
  }

  public incrementKey() {
    this._nextKey++;
  }

  public decrementKey() {
    this._nextKey = Math.max(1, this._nextKey - 1);
  }

  public resetKey() {
    this._nextKey = 1;
  }

  getNextKey(): number {
    const value = this.nextKey;
    this.incrementKey();
    return value;
  }

  // history
  public addHistory(history: History) {
    this._history.push(history);
  }

  public emptyHistory(): boolean {
    if (this._history === undefined) return true;
    return this._history.length === 0;
  }

  public clear() {
    this._polygons.forEach(polygon => {
      polygon.clear();
    });
    this.resetKey();

    while (this._polygons.length > 0) {
      this._polygons.pop();
    }

    while (this._history.length > 0) {
      this._history.pop();
    }
  }

  public emptyAnchors() {
    return this._polygons[this._polygons.length - 1].emptyAnchors();
  }

  public anchorSetDefaults(anchor: any): Anchor {
    anchor.innerRadius = ANCHOR_INNER_RADIUS;
    anchor.outerRadius = ANCHOR_OUTER_RADIUS;
    anchor.color = ANCHOR_COLOR;
    return anchor;
  }

  public addAnchor(anchor: Anchor) {
    let i = this._polygons.length - 1;
    for (; i >= 0; i--) {
      if (
        anchor.categoryId == this._polygons[i].categoryId &&
        !this._polygons[i].isPolygonFinished
      ) {
        this._polygons[i].addAnchor(anchor);
        break;
      }
    }
    if (i < 0) {
      i = 0;
      for (; i < this._polygons.length; i++) {
        if (
          this._polygons[i].categoryId == -1 &&
          !this._polygons[i].isPolygonFinished
        ) {
          this._polygons[i].addAnchor(anchor);
          break;
        }
      }
      if (i >= this._polygons.length) {
        this.addPolygon();
        this._polygons[this._polygons.length - 1].addAnchor(anchor);
      }
    }
  }

  public addEdge(edge: Edge) {
    for (let i = this._polygons.length - 1; i >= 0; i--) {
      if (edge.categoryId == this._polygons[i].categoryId) {
        this._polygons[i].addEdge(edge);
        if (this._polygons[i].isPolygonFinished) {
          this.addPolygon();
        }
        break;
      }
    }
  }

  public anchorsSize() {
    return this._polygons[this._polygons.length - 1].anchorsSize();
  }

  public edgesSize() {
    return this._polygons[this._polygons.length - 1].edgesSize();
  }

  public findAnchor(anchor: Anchor) {
    for (let i = 0; i < this._polygons.length; i++) {
      if (this._polygons[i].anchors.indexOf(anchor) != -1) {
        return i;
      }
    }
    return -1;
  }

  public findEdge(edge: Edge) {
    for (let i = 0; i < this._polygons.length; i++) {
      if (this._polygons[i].edges.indexOf(edge) != -1) {
        return i;
      }
    }
    return -1;
  }

  private mapDbPointToAnchor(
    point: AnnotationPointEntity,
    categoryId: number
  ): Anchor {
    return this.anchorSetDefaults({
      id: this.getNextKey(),
      x: point.x,
      y: point.y,
      stroke: "black",
      categoryId: categoryId
    });
  }

  public setPolygonsFromAnnotations(annotations: AnnotationWithPointsEntity[]) {
    this.clear();
    let allFinished = true;
    let unfinishedPolygon = new SinglePolygonAnnotationData(0);
    annotations.forEach(annotation => {
      if (annotation.points.length > 0) {
        const polygon = new SinglePolygonAnnotationData(annotation.id);
        const anchors = annotation.points.map(point => {
          return this.mapDbPointToAnchor(point, annotation.category);
        });
        for (let i = 0; i < anchors.length; i++) {
          polygon.addAnchor(anchors[i]);
        }
        polygon.isPolygonFinished = annotation.is_finished;
        for (let i = 1; i < anchors.length; i++) {
          polygon.addEdge({
            id: this.getNextKey(),
            pointSet: {
              first: { x: anchors[i - 1].x, y: anchors[i - 1].y },
              second: { x: anchors[i].x, y: anchors[i].y }
            },
            categoryId: anchors[0].categoryId
          });
        }
        if (annotation.is_finished) {
          polygon.addEdge({
            id: this.getNextKey(),
            pointSet: {
              first: {
                x: anchors[anchors.length - 1].x,
                y: anchors[anchors.length - 1].y
              },
              second: { x: anchors[0].x, y: anchors[0].y }
            },
            categoryId: anchors[0].categoryId
          });
          this._polygons.push(polygon);
        } else {
          unfinishedPolygon = polygon;
          allFinished = false;
        }
      }
    });

    // always at most one polygon is unfinished
    if (allFinished) {
      this.addPolygon();
    } else {
      // make sure that the unfinished polygon is always at the end
      this._polygons.push(unfinishedPolygon);
    }
  }

  mapPolygonsToAnnotations(taskId: number, photoId: number): Annotation[] {
    return this._polygons
      .map(polygon => {
        return new Annotation(
          polygon.id,
          taskId,
          photoId,
          polygon.categoryId,
          polygon.isPolygonFinished,
          AnnotationType.Polygons,
          polygon.mapAnchorsToPoints()
        );
      })
      .filter(annotation => {
        return annotation.points.length > 0;
      });
  }

  updatePolygonIds(annotations: Annotation[]) {
    for (let i = 0; i < annotations.length; i++) {
      this._polygons[i].id = annotations[i].id;
    }
  }
}
