var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{directives:[{name:"shortkey",rawName:"v-shortkey",value:({
    save: ['s'],
    clear: ['c'],
    undo: ['ctrl', 'z'],
    redo: ['r'],
    move: ['m'],
    delete: ['d']
  }),expression:"{\n    save: ['s'],\n    clear: ['c'],\n    undo: ['ctrl', 'z'],\n    redo: ['r'],\n    move: ['m'],\n    delete: ['d']\n  }"}],style:({ cursor: _vm.cursorStyle }),attrs:{"tag":"div"},on:{"shortkey":_vm.hotkeyHandler}},[_c('v-row',[_c('v-stage',{ref:"stage",attrs:{"config":_vm.stageConfig},on:{"click":_vm.handleStageClick,"touchstart":_vm.handleStageClick}},[_c('v-layer',{ref:"layer",on:{"mouseleave":_vm.handleMouseLeave,"mouseover":_vm.handleMouseMove}},[_c('v-group',{ref:"group",attrs:{"config":{
            draggable: !_vm.isZoomNormalMode,
            dragBoundFunc: _vm.dragImageBoundaries
          }}},[_c('v-image',{attrs:{"config":{
              image: _vm.imageData.currentWindowImage,
              scaleX: _vm.zoomValue,
              scaleY: _vm.zoomValue,
              offsetX: _vm.imageOffset.x,
              offsetY: _vm.imageOffset.y
            }},on:{"wheel":_vm.zoom,"click":_vm.addAnchor,"mousedown":_vm.handleMouseDown,"mouseup":_vm.handleMouseUp,"mousemove":_vm.handleMouseMove}}),_vm._l((_vm.filteredRectangles),function(rect){return _c('v-rect',{key:rect.id,attrs:{"config":{
              rotation: 0,
              id: String(rect.id),
              x: _vm.zoomManager.zoomedPositionX(rect.x),
              y: _vm.zoomManager.zoomedPositionY(rect.y),
              width: rect.width * _vm.zoomValue * _vm.imageData.imageScale,
              height: rect.height * _vm.zoomValue * _vm.imageData.imageScale,
              stroke: rect.color,
              draggable:
                rect.id == _vm.selectedRectangleId &&
                _vm.currentZoomMode === 'Normal' &&
                rect.categoryId === _vm.currentCategory.id
            }},on:{"click":_vm.addAnchor,"mousedown":_vm.handleMouseDown,"mouseup":_vm.handleMouseUp,"mousemove":_vm.handleMouseMove,"wheel":_vm.zoom,"transformend":_vm.handleTransformEnd,"dragend":function($event){return _vm.updateRectangleAfterMove($event, rect)},"dragstart":function($event){return _vm.saveMoveHistory($event, rect)}}})}),_c('v-transformer',{ref:"trans",attrs:{"config":{
              rotateEnabled: false,
              boundBoxFunc: _vm.rectangleResizeBoundingBox
            }},on:{"click":_vm.addAnchor}}),_vm._l((_vm.filteredPolygons),function(polygon){return _c('v-group',{key:_vm.polygonAnnotationData.polygons.indexOf(polygon),ref:"drawings",refInFor:true,attrs:{"config":{ draggable: false }},on:{"wheel":_vm.zoom,"mousedown":_vm.handleMouseDown,"mouseup":_vm.handleMouseUp,"mousemove":_vm.handleMouseMove}},[_vm._l((polygon.edges),function(edge){return _c('v-group',{key:edge.id,on:{"click":function($event){return _vm.addMiddlePoint(edge)}}},[_c('v-line',{attrs:{"config":{
                  points: _vm.pointSetToArray(edge.pointSet).map(
                    _vm.zoomTransformPointSet
                  ),
                  tension: 0,
                  stroke: _vm.categoryColors.get(edge.categoryId),
                  draggable: false
                }}}),_c('v-line',{attrs:{"config":{
                  points: _vm.pointSetToArray(edge.pointSet).map(
                    _vm.zoomTransformPointSet
                  ),
                  tension: 0,
                  stroke: 'white',
                  strokeWidth: 12,
                  opacity: 0.0,
                  draggable: false
                }}})],1)}),_vm._l((polygon.anchors),function(anchor){return _c('v-group',{key:anchor.id,attrs:{"config":{
                x: _vm.zoomManager.zoomedPositionX(anchor.x),
                y: _vm.zoomManager.zoomedPositionY(anchor.y),
                id: anchor.id,
                draggable:
                  _vm.isZoomNormalMode &&
                  _vm.currentCategory.id === anchor.categoryId,
                dragBoundFunc: _vm.dragAnchorBoundaries,
                radius: anchor.innerRadius,
                fill: anchor.color,
                stroke: anchor.stroke
              }},on:{"click":function($event){return _vm.handleClickOnPolygonNode(anchor)},"dragmove":function($event){return _vm.updatePoly($event, anchor)},"dragend":function($event){return _vm.saveAnchors($event, anchor)},"dragstart":function($event){return _vm.saveHistory($event, anchor)},"mouseover":function($event){return _vm.activateAnchor(polygon, anchor)},"mouseleave":function($event){return _vm.deactivateAnchor(polygon, anchor)}}},[_c('v-circle',{attrs:{"config":{
                  id: anchor.id,
                  radius: anchor.innerRadius,
                  fill: anchor.color,
                  stroke: anchor.stroke
                }}}),_c('v-circle',{attrs:{"config":{
                  id: anchor.id,
                  radius: anchor.outerRadius,
                  opacity: 0.0,
                  stroke: anchor.stroke
                }}})],1)})],2)})],2)],1)],1)],1),_c('v-row',{staticStyle:{"height":"60px"},attrs:{"align":"center"}},[_c('v-btn',{attrs:{"large":""},on:{"click":_vm.clear}},[_vm._v("Clear (c)")]),_c('v-btn',{attrs:{"large":"","disabled":_vm.polygonAnnotationData.history.length === 0 &&
          _vm.rectangleAnnotationData.history.length === 0},on:{"click":_vm.undo}},[_vm._v(" Undo "),_c('br'),_vm._v(" (ctrl + z) ")]),_c('v-btn',{attrs:{"large":"","disabled":_vm.polygonActionsToRedo.length === 0 &&
          _vm.rectangleActionsToRedo.length === 0},on:{"click":_vm.redo}},[_vm._v(" Redo (r) ")]),_c('v-btn',{attrs:{"large":"","disabled":!_vm.isRectangleSelected},on:{"click":_vm.deleteSelection}},[_vm._v(" Delete (d) ")]),_c('v-spacer'),_c('v-btn',{attrs:{"x-large":"","id":"save-button"},on:{"click":_vm.save}},[_vm._v("Save (s)")]),_c('v-chip',{attrs:{"large":"","label":""},on:{"click":_vm.toggleMode}},[_vm._v(" Mode (M): "+_vm._s(_vm.currentZoomMode)+" ")]),_c('v-spacer')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }