import { Point } from "@/models/Point";
import { Annotation } from "@/models/Annotation";
import { AnnotationWithPointsEntity } from "@/entities/AnnotationWithPoints.entity";
import { AnnotationType } from "@/models/enums/AnnotationType";

export interface RectangleData {
  id: number; // negative value if annotation wasn't saved yet, otherwise real id from database
  x: number;
  y: number;
  width: number;
  height: number;
  color: string;
  categoryId: number;
}

export enum ActionType {
  AddRectangle,
  Resize,
  MoveRectangle
}

export interface History {
  actionType: ActionType;
  rectangleId: number;
  x: number;
  y: number;
  width: number;
  height: number;
  categoryId: number;
}

export class RectangleAnnotationData {
  private _rectangles: Array<RectangleData>;
  private _nextKey: number;
  private _history: Array<History>;

  constructor() {
    this._rectangles = Array<RectangleData>();
    this._nextKey = -1;
    this._history = Array<History>();
  }

  public get nextKey(): number {
    return this._nextKey;
  }

  public get rectangles(): Array<RectangleData> {
    return this._rectangles;
  }

  public setRectangles(rectangles: Array<RectangleData>) {
    this._rectangles = rectangles;
  }

  public get history(): Array<History> {
    return this._history;
  }

  public decrementKey() {
    this._nextKey = Math.min(-1, this._nextKey + 1);
  }

  public incrementKey() {
    this._nextKey--;
  }
  public resetKey() {
    this._nextKey = -1;
  }

  public addRectangle(rectangle: RectangleData) {
    this._rectangles.push(rectangle);
  }

  public addHistory(history: History) {
    this._history.push(history);
  }

  public isEmptyHistory(): boolean {
    return this._history.length === 0;
  }

  public getHistoryForCategory(categoryId: number): Array<History> {
    return this._history.filter(history => {
      return history.categoryId == categoryId;
    });
  }

  public isEmptyHistoryForCategory(categoryId: number): boolean {
    return this.getHistoryForCategory(categoryId).length === 0;
  }

  public historySize(): number {
    return this._history.length;
  }

  removeLastHistoryForCategory(categoryId: number) {
    const removeIndex =
      this._history.length -
      this._history
        .map(history => history.categoryId)
        .reverse()
        .indexOf(categoryId) -
      1;
    this._history.splice(removeIndex, 1);
  }

  getLastRectangle(): RectangleData {
    if (this.isEmptyRectangles()) {
      console.error("Attempting to get last rectangle from empty list");
    }
    const lastIndex = this._rectangles.length - 1;
    return this._rectangles[lastIndex];
  }

  popRectangle() {
    this._rectangles.pop();
  }

  removeRectangle(rectangleId: number) {
    const removeIndex = this._rectangles
      .map(rectangle => rectangle.id)
      .indexOf(rectangleId);
    this._rectangles.splice(removeIndex, 1);
  }

  clearRectangles() {
    while (!this.isEmptyRectangles()) {
      this._rectangles.pop();
    }
  }

  clearHistory() {
    while (!this.isEmptyHistory()) {
      this._history.pop();
    }
  }

  isEmptyRectangles() {
    if (this._rectangles == undefined) {
      return true;
    }
    return this._rectangles.length === 0;
  }

  clearAll() {
    this.resetKey();
    this.clearRectangles();
    this.clearHistory();
  }

  private clearRectanglesForCategory(categoryId: number) {
    this._rectangles = this._rectangles.filter(rectangle => {
      return rectangle.categoryId != categoryId;
    });
  }

  private clearHistoryForCategory(categoryId: number) {
    this._history = this._history.filter(history => {
      return history.categoryId != categoryId;
    });
  }

  clearForCategory(categoryId: number) {
    this.clearRectanglesForCategory(categoryId);
    this.clearHistoryForCategory(categoryId);
  }

  mapRectanglesToAnnotations(taskId: number, photoId: number) {
    return this._rectangles.map(rectangle => {
      return new Annotation(
        rectangle.id,
        taskId,
        photoId,
        rectangle.categoryId,
        true,
        AnnotationType.Rectangles,
        [
          new Point(rectangle.x, rectangle.y),
          new Point(
            rectangle.x + rectangle.width,
            rectangle.y + rectangle.height
          )
        ]
      );
    });
  }

  setRectanglesFromAnnotations(
    annotations: AnnotationWithPointsEntity[],
    colors: Map<number, string>
  ) {
    this.clearAll();
    annotations.forEach(annotation => {
      const points = annotation.points.sort((p1, p2) => p1.index - p2.index);
      this._rectangles.push({
        id: annotation.id,
        x: points[0].x,
        y: points[0].y,
        width: points[1].x - points[0].x,
        height: points[1].y - points[0].y,
        color: colors.get(annotation.category)!,
        categoryId: annotation.category
      });
    });
  }

  private updateRectangleIdInHistory(rectangleIndex: number, newId: number) {
    this._history
      .filter(history => {
        return history.rectangleId == this._rectangles[rectangleIndex].id;
      })
      .forEach(history => {
        history.rectangleId = newId;
      });
  }

  updateRectangleIds(annotations: Annotation[]) {
    for (let i = 0; i < annotations.length; i++) {
      this.updateRectangleIdInHistory(i, annotations[i].id);
      this._rectangles[i].id = annotations[i].id;
    }
  }
}
