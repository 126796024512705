











































































































































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import {
  ActionType as PolygonActionType,
  // eslint-disable-next-line no-unused-vars
  Anchor,
  // eslint-disable-next-line no-unused-vars
  Edge,
  // eslint-disable-next-line no-unused-vars
  History as PolygonHistory,
  // eslint-disable-next-line no-unused-vars
  PointSet,
  MultiPolygonAnnotationData,
  // eslint-disable-next-line no-unused-vars
  SinglePolygonAnnotationData
} from "@/models/PolygonAnnotationData";

// eslint-disable-next-line no-unused-vars
import { ImageData } from "@/models/ImageData";
import { annotationService } from "@/services/annotation.service";
// eslint-disable-next-line no-unused-vars
import { AnnotationEntity } from "@/entities/Annotation.entity";
// import { pointService } from "@/services/point.service";
import { ZoomManager } from "@/models/ZoomManager";
// eslint-disable-next-line no-unused-vars
import { Category } from "@/models/Category";
// eslint-disable-next-line no-unused-vars
import { Point } from "@/models/Point";
// eslint-disable-next-line no-unused-vars
import { AnnotationWithPointsEntity } from "@/entities/AnnotationWithPoints.entity";
import {
  ActionType,
  ActionType as RectangleActionType,
  // eslint-disable-next-line no-unused-vars
  History as RectangleHistory,
  RectangleAnnotationData,
  // eslint-disable-next-line no-unused-vars
  RectangleData
} from "@/models/RectangleAnnotationData";
import { AnnotationType } from "@/models/enums/AnnotationType";
import Konva from "konva";
import { colors, colorsSize } from "@/shared/constants";

interface StageConfig {
  width: number;
  height: number;
}

const width = window.innerWidth;
const height = window.innerHeight;
const precision = 0.001;

@Component
export default class ImageAnnotations extends Vue {
  @Prop() private imageData!: ImageData;
  @Prop() private currentCategory!: Category;
  @Prop() private polygonCategoryIds!: number[];
  @Prop() private rectangleCategoryIds!: number[];
  @Prop() private categoryVisibility!: boolean[];

  stageConfig: StageConfig = {
    width: width,
    height: height
  };
  polygonAnnotationData = new MultiPolygonAnnotationData();
  rectangleAnnotationData = new RectangleAnnotationData();
  isActive = false;
  selectedRectangleId = "";

  photoCollectionId = 0;
  taskId = 0;

  undoTurn: AnnotationType[] = [];
  redoTurn: AnnotationType[] = [];
  polygonActionsToRedo: PolygonHistory[] = [];
  rectangleActionsToRedo: RectangleHistory[] = [];
  positionsBeforeUndo: Point[] = [];

  zoomManager: ZoomManager = new ZoomManager();

  categoryColors = new Map();

  // Watch
  @Watch("imageData.currentWindowImage", { deep: true })
  imageHandler(): void {
    this.zoomManager.resetZoom();

    // reset position after dragging
    const group: any = this.$refs.group;
    group.getNode().position({ x: 0, y: 0 });

    if (this.imageId != 0) {
      this.resetRedo();
      this.getAnnotations();
      this.stageConfig = {
        width: this.imageData.currentWindowImage.width,
        height: this.imageData.currentWindowImage.height
      };
      this.zoomManager.imageSize = {
        width: this.imageData.currentWindowImage.width,
        height: this.imageData.currentWindowImage.height
      };
      this.zoomManager.adjustMaxZoom();
    }
  }

  @Watch("currentCategory")
  resetAfterCategoryChange() {
    this.detachTransformer();
  }

  @Watch("imageData.images")
  imagesLoadingHandler() {
    this.polygonAnnotationData = new MultiPolygonAnnotationData();
    this.rectangleAnnotationData = new RectangleAnnotationData();
    this.assignColorsToCategories();
  }

  @Watch("imageData.currentImageIndex")
  imageNumberHandler() {
    this.polygonAnnotationData.clear();
    this.detachTransformer();
  }

  @Watch("imageData.imageScale", { immediate: true })
  imageScaleHandler() {
    this.zoomManager.imageScale = this.imageData.imageScale;
  }

  // Computed
  get imageIndex() {
    return this.imageData.currentImageIndex;
  }
  get imageId() {
    if (this.imageData.images.length == 0) {
      return 0;
    }
    return this.imageData.images[this.imageIndex].id;
  }

  get rectanglesForCurrentCategory() {
    return this.rectangleAnnotationData.rectangles.filter(rectangleData => {
      return rectangleData.categoryId == this.currentCategory.id;
    });
  }

  get filteredRectangles() {
    return this.rectangleAnnotationData.rectangles.filter(rect => {
      return this.categoryVisibility[rect.categoryId];
    });
  }

  get filteredPolygons() {
    return this.polygonAnnotationData.polygons.filter(polygon => {
      if (polygon.categoryId > 0) {
        return this.categoryVisibility[polygon.categoryId];
      } else {
        return true;
      }
    });
  }

  getNextKey(): number {
    const value = this.rectangleAnnotationData.nextKey;
    this.rectangleAnnotationData.incrementKey();
    return value;
  }

  // Zoom computed values
  get zoomValue() {
    return this.zoomManager.zoomValue;
  }
  get imageOffset() {
    return this.zoomManager.imageOffset;
  }
  get cursorStyle() {
    return this.zoomManager.cursorStyle;
  }
  get isZoomNormalMode() {
    return this.zoomManager.isZoomNormalMode();
  }
  get currentZoomMode() {
    return this.isZoomNormalMode ? "Normal" : "Move";
  }

  get isPolygons() {
    return this.currentCategory.annotationType == AnnotationType.Polygons;
  }

  get isRectangles() {
    return this.currentCategory.annotationType == AnnotationType.Rectangles;
  }

  assignColorsToCategories() {
    const categoryIds = this.rectangleCategoryIds.concat(
      this.polygonCategoryIds
    );
    if (categoryIds.length > colorsSize) {
      console.warn("Random colors for categories over #10");
    }
    for (let i = 0; i < Math.min(colorsSize, categoryIds.length); i++) {
      this.categoryColors.set(categoryIds[i], colors[i]);
    }
    for (let i = colorsSize; i < categoryIds.length; i++) {
      this.categoryColors.set(categoryIds[i], Konva.Util.getRandomColor());
    }
  }

  created() {
    this.photoCollectionId = Number(
      this.$route.query.photoCollectionId as string
    );
    this.taskId = Number(this.$route.query.taskId as string);
  }

  mounted() {
    this.imageHandler();
    this.imagesLoadingHandler();
  }

  pointSetToArray(pointSet: PointSet) {
    return [
      pointSet.first.x,
      pointSet.first.y,
      pointSet.second.x,
      pointSet.second.y
    ];
  }

  resetRedo() {
    this.polygonActionsToRedo = [];
    this.rectangleActionsToRedo = [];
    this.positionsBeforeUndo = [];
  }

  addAnchor() {
    if (!this.isPolygons) {
      return;
    }
    this.resetRedo();
    let [x, y] = this.getMousePosition();
    this.handleMouseClick(x, y);
  }

  activateAnchor(polygon: SinglePolygonAnnotationData, anchor: Anchor) {
    if (this.currentCategory.id == anchor.categoryId) {
      polygon.activateAnchor(anchor);
    }
  }

  deactivateAnchor(polygon: SinglePolygonAnnotationData, anchor: Anchor) {
    if (this.currentCategory.id == anchor.categoryId) {
      polygon.deactivateAnchor(anchor);
    }
  }

  // handles polygons
  handleMouseClick(
    x: number,
    y: number,
    redo = false,
    polygonIndex = 0,
    categoryId = -1
  ) {
    if (!this.isPolygons && !redo) {
      return;
    }
    if (!this.zoomManager.isZoomNormalMode()) {
      return;
    }
    const historyX = x;
    const historyY = y;
    if (!redo) {
      const [offsetX, offsetY] = this.getGroupOffset();
      x -= offsetX;
      y -= offsetY;
      x = this.zoomManager.nominalPositionX(x);
      y = this.zoomManager.nominalPositionY(y);
    }

    let annotationData:
      | SinglePolygonAnnotationData
      | MultiPolygonAnnotationData;

    if (!redo) {
      annotationData = this.polygonAnnotationData;
    } else {
      annotationData = this.polygonAnnotationData.polygons[polygonIndex];
    }

    if (!annotationData.emptyAnchors()) {
      const lastIndex = annotationData.anchorsSize() - 1;
      annotationData.anchors[lastIndex].stroke = "black";
    }

    if (categoryId == -1) {
      categoryId = this.currentCategory.id;
    }

    annotationData.addAnchor(
      annotationData.anchorSetDefaults({
        id: this.polygonAnnotationData.getNextKey(),
        categoryId: categoryId,
        x: x,
        y: y,
        stroke: "green"
      })
    );

    let anchorCount = 0;
    let previousAnchor;
    let i = this.polygonAnnotationData.polygons.length - 1;
    for (; i >= 0; i--) {
      if (
        categoryId == this.polygonAnnotationData.polygons[i].categoryId &&
        !this.polygonAnnotationData.polygons[i].isPolygonFinished
      ) {
        anchorCount = this.polygonAnnotationData.polygons[i].anchorsSize();
        previousAnchor = this.polygonAnnotationData.polygons[i].anchors[
          anchorCount - 2
        ];
        break;
      }
    }

    this.polygonAnnotationData.addHistory({
      actionType: PolygonActionType.AddAnchor,
      anchorIndex: 0,
      edgeIndex: 0,
      edge: {
        id: 0,
        pointSet: { first: { x: 0, y: 0 }, second: { x: 0, y: 0 } },
        categoryId: categoryId
      },
      x: historyX,
      y: historyY,
      polygonIndex: redo ? polygonIndex : i
    });
    this.undoTurn.push(AnnotationType.Polygons);

    if (anchorCount > 1 && previousAnchor != undefined) {
      annotationData.addEdge({
        id: this.polygonAnnotationData.getNextKey(),
        pointSet: {
          first: { x: previousAnchor.x, y: previousAnchor.y },
          second: { x: x, y: y }
        },
        categoryId: categoryId
      });
    }
  }

  updatePoly(event: any, anchor: Anchor) {
    if (this.currentCategory.id != anchor.categoryId) {
      return;
    }
    if (!this.zoomManager.isZoomNormalMode()) {
      return;
    }
    const polygonIndex = this.polygonAnnotationData.findAnchor(anchor);
    if (polygonIndex == -1) {
      return;
    }
    const polygon = this.polygonAnnotationData.polygons[polygonIndex];
    const index = polygon.anchors.indexOf(anchor);
    const x = this.zoomManager.nominalPositionNoDragX(event.target.attrs.x);
    const y = this.zoomManager.nominalPositionNoDragY(event.target.attrs.y);

    if (polygon.edgesSize() > index) {
      polygon.edges[index].pointSet.first = {
        x: x,
        y: y
      };
    }
    if (index === 0 && polygon.isPolygonFinished) {
      const lastEdgeIndex = polygon.edgesSize() - 1;
      polygon.edges[lastEdgeIndex].pointSet.second = {
        x: x,
        y: y
      };
    }
    if (index > 0) {
      polygon.edges[index - 1].pointSet.second = {
        x: x,
        y: y
      };
    }
  }

  getMousePosition() {
    const stage: any = this.$refs.stage;
    const mousePos = stage.getNode().getPointerPosition();
    const x = mousePos.x;
    const y = mousePos.y;
    return [x, y];
  }

  // returns drag offset of group "drawings"
  getGroupOffset() {
    const drawings: any = this.$refs.drawings;
    const attrs = drawings[0].getNode().attrs;
    const x = attrs.x != null ? attrs.x : 0;
    const y = attrs.y != null ? attrs.y : 0;
    return [x, y];
  }

  saveHistory(event: any, anchor: Anchor) {
    if (this.currentCategory.id != anchor.categoryId) {
      return;
    }
    if (!this.zoomManager.isZoomNormalMode()) {
      return;
    }
    let x = event.target.attrs.x;
    let y = event.target.attrs.y;
    const [offsetX, offsetY] = this.getGroupOffset();
    x -= offsetX;
    y -= offsetY;
    x = this.zoomManager.nominalPositionNoDragX(x);
    y = this.zoomManager.nominalPositionNoDragY(y);
    const polygonIndex = this.polygonAnnotationData.findAnchor(anchor);
    const index = this.polygonAnnotationData.polygons[
      polygonIndex
    ].anchors.indexOf(anchor);
    this.polygonAnnotationData.addHistory({
      actionType: PolygonActionType.DragAnchor,
      anchorIndex: index,
      x: x,
      y: y,
      edgeIndex: 0,
      edge: {
        id: 0,
        pointSet: { first: { x: 0, y: 0 }, second: { x: 0, y: 0 } },
        categoryId: this.currentCategory.id
      },
      polygonIndex: polygonIndex
    });
    this.undoTurn.push(AnnotationType.Polygons);
  }

  saveAnchors(event: any, anchor: Anchor) {
    if (this.currentCategory.id != anchor.categoryId) {
      return;
    }
    if (!this.zoomManager.isZoomNormalMode()) {
      return;
    }
    const polygonIndex = this.polygonAnnotationData.findAnchor(anchor);
    if (polygonIndex == -1) {
      return;
    }
    const polygon = this.polygonAnnotationData.polygons[polygonIndex];
    const index = polygon.anchors.indexOf(anchor);
    polygon.anchors[index].x = this.zoomManager.nominalPositionNoDragX(
      event.target.attrs.x
    );
    polygon.anchors[index].y = this.zoomManager.nominalPositionNoDragY(
      event.target.attrs.y
    );
  }

  handleClickOnPolygonNode(anchor: Anchor, redo = false, categoryId = -1) {
    if (categoryId == -1) {
      categoryId = this.currentCategory.id;
    }
    if (categoryId != anchor.categoryId) {
      return;
    }
    if (!this.zoomManager.isZoomNormalMode()) {
      return;
    }
    const polygonIndex = this.polygonAnnotationData.findAnchor(anchor);
    if (polygonIndex == -1) {
      return;
    }
    const polygon = this.polygonAnnotationData.polygons[polygonIndex];
    const index = polygon.anchors.indexOf(anchor);
    if (index !== 0) this.resetRedo();
    const lastAnchorIndex = polygon.anchorsSize() - 1;
    if (
      polygon.anchorsSize() > 2 &&
      (index === 0 || index == lastAnchorIndex) &&
      !polygon.isPolygonFinished
    ) {
      polygon.isPolygonFinished = true;
      const lastAnchor = polygon.anchors[lastAnchorIndex];
      const firstAnchor = polygon.anchors[0];
      polygon.anchors[lastAnchorIndex].stroke = "black";

      this.polygonAnnotationData.addHistory({
        actionType: PolygonActionType.AddAnchor,
        anchorIndex: 0,
        edgeIndex: 0,
        edge: {
          id: 0,
          pointSet: { first: { x: 0, y: 0 }, second: { x: 0, y: 0 } },
          categoryId: categoryId
        },
        x: 0,
        y: 0,
        polygonIndex: polygonIndex
      });
      this.undoTurn.push(AnnotationType.Polygons);

      if (!redo) {
        this.polygonAnnotationData.addEdge({
          id: this.polygonAnnotationData.getNextKey(),
          pointSet: {
            first: { x: lastAnchor.x, y: lastAnchor.y },
            second: { x: firstAnchor.x, y: firstAnchor.y }
          },
          categoryId: categoryId
        });
      } else {
        polygon.addEdge({
          id: this.polygonAnnotationData.getNextKey(),
          pointSet: {
            first: { x: lastAnchor.x, y: lastAnchor.y },
            second: { x: firstAnchor.x, y: firstAnchor.y }
          },
          categoryId: categoryId
        });
      }
    }
  }

  addMiddlePoint(edge: Edge) {
    if (this.currentCategory.id != edge.categoryId) {
      return;
    }
    this.resetRedo();
    const [x, y] = this.getMousePosition();
    this.handleClickOnPolygonEdge(edge, x, y);
  }

  handleClickOnPolygonEdge(
    edge: Edge,
    x: number,
    y: number,
    categoryId = -1,
    redo = false
  ) {
    if (categoryId == -1) {
      categoryId = this.currentCategory.id;
    }
    if (categoryId != edge.categoryId) {
      return;
    }
    if (!this.zoomManager.isZoomNormalMode()) {
      return;
    }
    const polygonIndex = this.polygonAnnotationData.findEdge(edge);
    if (polygonIndex == -1) {
      return;
    }
    const polygon = this.polygonAnnotationData.polygons[polygonIndex];
    const edgeIndex = polygon.edges.indexOf(edge);
    const pointSet = edge.pointSet;
    let [middleX, middleY] = [
      this.zoomManager.nominalPositionX(x),
      this.zoomManager.nominalPositionY(y)
    ];
    if (redo) {
      middleX = x;
      middleY = y;
    }
    if (edgeIndex >= 0) {
      polygon.anchors.splice(
        edgeIndex + 1,
        0,
        this.polygonAnnotationData.anchorSetDefaults({
          id: this.polygonAnnotationData.getNextKey(),
          x: middleX,
          y: middleY,
          stroke: "black",
          categoryId: categoryId
        })
      );
      polygon.edges.splice(
        edgeIndex,
        1,
        {
          id: this.polygonAnnotationData.getNextKey(),
          pointSet: {
            first: pointSet.first,
            second: { x: middleX, y: middleY }
          },
          categoryId: categoryId
        },
        {
          id: this.polygonAnnotationData.getNextKey(),
          pointSet: {
            first: { x: middleX, y: middleY },
            second: pointSet.second
          },
          categoryId: categoryId
        }
      );
    }
    this.polygonAnnotationData.addHistory({
      actionType: PolygonActionType.CreateMiddlePoint,
      anchorIndex: edgeIndex + 1,
      edgeIndex: edgeIndex,
      edge: edge,
      x: x,
      y: y,
      polygonIndex: polygonIndex
    });
    this.undoTurn.push(AnnotationType.Polygons);
  }

  handleMouseDown() {
    if (!this.isRectangles) {
      return;
    }
    if (!this.isZoomNormalMode || this.selectedRectangleId !== "") {
      return;
    }
    this.detachTransformer();
    this.isActive = true;
    const [x, y] = this.getMousePosition();
    this.rectangleAnnotationData.addRectangle({
      id: this.getNextKey(),
      x: this.zoomManager.nominalPositionX(x),
      y: this.zoomManager.nominalPositionY(y),
      width: 0,
      height: 0,
      color: this.categoryColors.get(this.currentCategory.id),
      categoryId: this.currentCategory.id
    });
  }

  handleMouseMove() {
    if (!this.isRectangles) {
      return;
    }
    if (
      !this.isZoomNormalMode ||
      !this.isActive ||
      this.rectangleAnnotationData.isEmptyRectangles()
    ) {
      return;
    }
    let [x, y] = this.getMousePosition();
    x = this.zoomManager.nominalPositionX(x);
    y = this.zoomManager.nominalPositionY(y);

    const rect = this.rectangleAnnotationData.getLastRectangle();
    const [oldX, oldY] = [rect.x, rect.y];

    rect.width = x - oldX;
    rect.height = y - oldY;
  }

  handleMouseUp() {
    if (!this.isRectangles) {
      return;
    }
    if (!this.isActive) {
      return;
    }
    this.isActive = false;
    const rect = this.rectangleAnnotationData.getLastRectangle();
    if (rect.id == 0 || rect.height === 0 || rect.width === 0) {
      this.rectangleAnnotationData.popRectangle();
    } else {
      this.rectangleAnnotationData.addHistory({
        actionType: ActionType.AddRectangle,
        rectangleId: rect.id,
        width: rect.width,
        height: rect.height,
        x: rect.x,
        y: rect.y,
        categoryId: rect.categoryId
      });
      this.undoTurn.push(AnnotationType.Rectangles);
    }
  }

  detachTransformer() {
    const trans: any = this.$refs.trans;
    const transformerNode = trans.getNode();
    transformerNode.nodes([]);
    transformerNode.getLayer().batchDraw();
  }

  handleStageClick(e: any) {
    if (!this.isRectangles) {
      return;
    }
    if (this.currentZoomMode == "Move") {
      return;
    }
    if (e.target) {
      if (e.target.getClassName() === "Image") {
        this.selectedRectangleId = "";
        this.updateTransformer();
        return;
      }
      if (e.target.getParent().className === "Transformer") {
        return;
      }
      if (e.target.getClassName() === "Rect") {
        this.selectedRectangleId = e.target.id();
        const rect = this.rectangleAnnotationData.rectangles.find(
          rect => String(rect.id) == this.selectedRectangleId
        );
        if (rect != undefined && rect.categoryId != this.currentCategory.id) {
          this.selectedRectangleId = "";
        }
        this.updateTransformer();
      } else {
        this.selectedRectangleId = "";
        this.updateTransformer();
      }
    }
  }

  isRectangleSelected() {
    return this.selectedRectangleId != "";
  }

  updateTransformer() {
    const trans: any = this.$refs.trans;
    const transformerNode = trans.getNode();
    const stage = transformerNode.getStage();
    const { selectedRectangleId } = this;
    const selectedNode = stage.findOne("#" + selectedRectangleId);
    if (!this.isRectangleSelected()) {
      this.detachTransformer();
      return;
    }
    if (selectedNode === transformerNode.node()) {
      return;
    }
    if (selectedNode) {
      transformerNode.nodes([selectedNode]);
      transformerNode.getLayer().batchDraw();
    } else {
      this.detachTransformer();
    }
  }

  handleTransformEnd(event: any) {
    // shape is transformed, let us save new attrs back to the node
    // find element in our state
    const rect = this.rectangleAnnotationData.rectangles.find(
      r => r.id === Number(this.selectedRectangleId)
    );
    if (rect) {
      this.rectangleAnnotationData.addHistory({
        actionType: ActionType.Resize,
        height: rect.height,
        width: rect.width,
        rectangleId: rect.id,
        x: rect.x,
        y: rect.y,
        categoryId: rect.categoryId
      });
      this.undoTurn.push(AnnotationType.Rectangles);
      // update the state
      rect.x -= this.zoomManager.imageOffset.x;
      rect.y -= this.zoomManager.imageOffset.y;
      rect.x = this.zoomManager.nominalPositionNoDragX(event.target.x());
      rect.y = this.zoomManager.nominalPositionNoDragY(event.target.y());
      rect.width =
        (event.target.width() * event.target.scaleX()) /
        this.zoomValue /
        this.imageData.imageScale;
      rect.height =
        (event.target.height() * event.target.scaleY()) /
        this.zoomValue /
        this.imageData.imageScale;
      event.target.scale({ x: 1, y: 1 });
    } else {
      console.error("rect not found in transform");
    }
  }

  handleMouseLeave() {
    if (!this.zoomManager.isZoomNormalMode()) {
      return;
    }

    let [x, y] = this.getMousePosition();
    let inside = true;

    if (x > this.stageConfig.width - precision) {
      x = this.stageConfig.width;
      inside = false;
    } else if (x < precision) {
      x = 0;
      inside = false;
    }
    if (y > this.stageConfig.height - precision) {
      y = this.stageConfig.height;
      inside = false;
    } else if (y < precision) {
      y = 0;
      inside = false;
    }

    if (!inside) {
      if (this.isActive && !this.rectangleAnnotationData.isEmptyRectangles()) {
        this.isActive = false;
        const rect = this.rectangleAnnotationData.getLastRectangle();
        const [oldX, oldY] = [rect.x, rect.y];
        [x, y] = [
          this.zoomManager.nominalPositionX(x),
          this.zoomManager.nominalPositionY(y)
        ];
        rect.width = x - oldX;
        rect.height = y - oldY;
      }
    }
  }

  saveMoveHistory(event: any, rect: RectangleData) {
    if (this.currentCategory.id != rect.categoryId) {
      return;
    }
    if (!this.zoomManager.isZoomNormalMode()) {
      return;
    }
    this.rectangleAnnotationData.addHistory({
      actionType: ActionType.MoveRectangle,
      rectangleId: rect.id,
      width: rect.width,
      height: rect.height,
      x: rect.x,
      y: rect.y,
      categoryId: rect.categoryId
    });
    this.undoTurn.push(AnnotationType.Rectangles);
  }

  updateRectangleAfterMove(event: any, rect: RectangleData) {
    if (this.currentCategory.id != rect.categoryId) {
      return;
    }
    if (!this.zoomManager.isZoomNormalMode()) {
      return;
    }
    rect.x -= this.zoomManager.imageOffset.x;
    rect.y -= this.zoomManager.imageOffset.y;
    rect.x = this.zoomManager.nominalPositionNoDragX(event.target.attrs.x);
    rect.y = this.zoomManager.nominalPositionNoDragY(event.target.attrs.y);
  }

  clear() {
    this.polygonAnnotationData.clear();
    this.resetRedo();
    this.rectangleAnnotationData.clearForCategory(this.currentCategory.id);
    this.detachTransformer();
    this.polygonActionsToRedo = [];
    this.rectangleActionsToRedo = [];
    this.imagesLoadingHandler();
  }

  undo() {
    const turn = this.undoTurn[this.undoTurn.length - 1];
    if (turn == AnnotationType.Polygons) {
      this.undoPolygon();
    } else if (turn == AnnotationType.Rectangles) {
      this.undoRectangle();
    }
    this.undoTurn.pop();
  }

  undoPolygon() {
    const history = this.polygonAnnotationData.history;

    if (history.length === 0) return;

    const action = history[history.length - 1];
    const polygon = this.polygonAnnotationData.polygons[action.polygonIndex];

    if (
      polygon.isPolygonFinished &&
      action.actionType === PolygonActionType.AddAnchor
    ) {
      // Remember that the polygon was finished
      action.anchorIndex = -1;
    }
    this.polygonActionsToRedo.push(action);
    this.redoTurn.push(AnnotationType.Polygons);

    switch (action.actionType) {
      case PolygonActionType.AddAnchor:
        this.undoAddAnchor(action);
        break;
      case PolygonActionType.DragAnchor:
        this.undoDragAnchor(action);
        break;
      case PolygonActionType.CreateMiddlePoint:
        this.undoCreateMiddlePoint(action);
        break;
      default:
        console.error("Unknown actionType");
    }
    history.pop();
  }

  undoAddAnchor(action: PolygonHistory) {
    const polygon = this.polygonAnnotationData.polygons[action.polygonIndex];
    if (polygon.isPolygonFinished) {
      polygon.isPolygonFinished = false;
      polygon.edges.pop();
      const lastIndex = polygon.anchorsSize() - 1;
      polygon.anchors[lastIndex].stroke = "green";
      return;
    }
    this.positionsBeforeUndo.push({
      x: polygon.anchors[polygon.anchors.length - 1].x,
      y: polygon.anchors[polygon.anchors.length - 1].y
    });
    polygon.edges.pop();
    polygon.anchors.pop();
    this.polygonAnnotationData.decrementKey();

    if (!polygon.emptyAnchors()) {
      const lastIndex = polygon.anchorsSize() - 1;
      polygon.anchors[lastIndex].stroke = "green";
    }
  }

  undoDragAnchor(action: PolygonHistory) {
    const polygon = this.polygonAnnotationData.polygons[action.polygonIndex];
    this.positionsBeforeUndo.push({
      x: polygon.anchors[action.anchorIndex].x,
      y: polygon.anchors[action.anchorIndex].y
    });
    this.dragAnchor(action);
  }

  undoCreateMiddlePoint(action: PolygonHistory) {
    const polygon = this.polygonAnnotationData.polygons[action.polygonIndex];
    this.positionsBeforeUndo.push({
      x: polygon.anchors[action.anchorIndex].x,
      y: polygon.anchors[action.anchorIndex].y
    });
    polygon.anchors.splice(action.anchorIndex, 1);
    polygon.edges.splice(action.edgeIndex, 2, action.edge);
  }

  undoRectangle() {
    const history = this.rectangleAnnotationData.history;

    if (history.length === 0) return;

    const action = history[history.length - 1];
    const actionCopy: RectangleHistory = Object.assign({}, action);

    const rect = this.rectangleAnnotationData.rectangles.find(
      r => r.id === action.rectangleId
    );
    if (rect) {
      actionCopy.x = rect.x;
      actionCopy.y = rect.y;
      actionCopy.width = rect.width;
      actionCopy.height = rect.height;
      actionCopy.categoryId = rect.categoryId;
    }
    this.rectangleActionsToRedo.push(actionCopy);
    this.redoTurn.push(AnnotationType.Rectangles);

    switch (action.actionType) {
      case ActionType.AddRectangle:
        this.undoAddRectangle(action);
        break;
      case ActionType.Resize:
        this.undoResize(action);
        break;
      case ActionType.MoveRectangle:
        this.undoMoveRectangle(action);
        break;
      default:
        console.error("Unknown actionType");
    }
    this.rectangleAnnotationData.history.pop();
    this.detachTransformer();
  }

  undoAddRectangle(action: RectangleHistory) {
    this.rectangleAnnotationData.removeRectangle(action.rectangleId);
    this.detachTransformer();
  }

  undoMoveRectangle(action: RectangleHistory) {
    const rect = this.rectangleAnnotationData.rectangles.find(
      r => r.id == action.rectangleId
    );
    if (rect) {
      rect.x = action.x;
      rect.y = action.y;
    }
  }

  undoResize(action: RectangleHistory) {
    const rect = this.rectangleAnnotationData.rectangles.find(
      r => r.id == action.rectangleId
    );
    if (rect) {
      rect.height = action.height;
      rect.width = action.width;
      rect.x = action.x;
      rect.y = action.y;
    }
  }

  redo() {
    const turn = this.redoTurn[this.redoTurn.length - 1];
    if (turn == AnnotationType.Polygons) {
      this.redoPolygon();
    } else if (turn == AnnotationType.Rectangles) {
      this.redoRectangle();
    }
    this.redoTurn.pop();
  }

  redoPolygon() {
    if (this.polygonActionsToRedo.length === 0) {
      return;
    }
    const actionsToRedo = this.polygonActionsToRedo;
    const actionToRedo = actionsToRedo[actionsToRedo.length - 1];
    actionsToRedo.pop();

    switch (actionToRedo.actionType) {
      case PolygonActionType.AddAnchor:
        this.redoAddAnchor(actionToRedo);
        break;
      case PolygonActionType.DragAnchor:
        this.redoDragAnchor(actionToRedo);
        break;
      case PolygonActionType.CreateMiddlePoint:
        this.redoMiddlePoint(actionToRedo);
        break;
      default:
        console.error("Unknown actionType");
    }
  }

  redoAddAnchor(actionToRedo: PolygonHistory) {
    const polygon = this.polygonAnnotationData.polygons[
      actionToRedo.polygonIndex
    ];
    const firstAnchor = polygon.anchors[0];

    // anchorIndex equal to -1 means that the polygon was finished
    if (actionToRedo.anchorIndex === -1) {
      this.handleClickOnPolygonNode(
        firstAnchor,
        true,
        actionToRedo.edge.categoryId
      );
    } else {
      const positionsBeforeUndo = this.positionsBeforeUndo;
      let x = positionsBeforeUndo[positionsBeforeUndo.length - 1].x;
      let y = positionsBeforeUndo[positionsBeforeUndo.length - 1].y;
      positionsBeforeUndo.pop();
      this.handleMouseClick(
        x,
        y,
        true,
        actionToRedo.polygonIndex,
        actionToRedo.edge.categoryId
      );
    }
  }

  redoDragAnchor(actionToRedo: PolygonHistory) {
    this.polygonAnnotationData.addHistory({
      actionType: PolygonActionType.DragAnchor,
      anchorIndex: actionToRedo.anchorIndex,
      x: actionToRedo.x,
      y: actionToRedo.y,
      edgeIndex: 0,
      edge: {
        id: 0,
        pointSet: { first: { x: 0, y: 0 }, second: { x: 0, y: 0 } },
        categoryId: this.currentCategory.id
      },
      polygonIndex: actionToRedo.polygonIndex
    });
    this.undoTurn.push(AnnotationType.Polygons);

    const positionsBeforeUndo = this.positionsBeforeUndo;
    actionToRedo.x = positionsBeforeUndo[positionsBeforeUndo.length - 1].x;
    actionToRedo.y = positionsBeforeUndo[positionsBeforeUndo.length - 1].y;
    positionsBeforeUndo.pop();

    this.dragAnchor(actionToRedo);
  }

  redoMiddlePoint(actionToRedo: PolygonHistory) {
    const positionsBeforeUndo = this.positionsBeforeUndo;
    let x = positionsBeforeUndo[positionsBeforeUndo.length - 1].x;
    let y = positionsBeforeUndo[positionsBeforeUndo.length - 1].y;
    positionsBeforeUndo.pop();
    // let x = actionToRedo.x;
    // let y = actionToRedo.y;
    const polygon = this.polygonAnnotationData.polygons[
      actionToRedo.polygonIndex
    ];
    const edges = polygon.edges;
    let edge = actionToRedo.edge;

    if (edges.indexOf(edge) === -1) {
      if (actionToRedo.edgeIndex >= edges.length)
        edge = edges[edges.length - 1];
      else edge = edges[actionToRedo.edgeIndex];
    }

    this.handleClickOnPolygonEdge(edge, x, y, edge.categoryId, true);
  }

  redoRectangle() {
    if (this.rectangleActionsToRedo.length === 0) {
      return;
    }
    const actionsToRedo = this.rectangleActionsToRedo;
    const actionToRedo = actionsToRedo[actionsToRedo.length - 1];
    actionsToRedo.pop();

    switch (actionToRedo.actionType) {
      case RectangleActionType.AddRectangle:
        this.redoAddRectangle(actionToRedo);
        break;
      case RectangleActionType.Resize:
        this.redoResize(actionToRedo);
        break;
      case RectangleActionType.MoveRectangle:
        this.redoMoveRectangle(actionToRedo);
        break;
      default:
        console.error("Unknown actionType");
    }
  }

  redoAddRectangle(actionToRedo: RectangleHistory) {
    this.rectangleAnnotationData.addRectangle({
      id: actionToRedo.rectangleId,
      x: actionToRedo.x,
      y: actionToRedo.y,
      width: actionToRedo.width,
      height: actionToRedo.height,
      color: this.categoryColors.get(actionToRedo.categoryId),
      categoryId: actionToRedo.categoryId
    });
    this.rectangleAnnotationData.addHistory(actionToRedo);
    this.undoTurn.push(AnnotationType.Rectangles);
  }

  getRectCopy(actionToRedo: RectangleHistory) {
    const rectBeforeRedo = this.rectangleAnnotationData.rectangles.find(
      r => r.id === actionToRedo.rectangleId
    );
    return Object.assign({}, rectBeforeRedo);
  }

  redoResize(actionToRedo: RectangleHistory) {
    const rectCopy = this.getRectCopy(actionToRedo);
    this.undoResize(actionToRedo);
    if (rectCopy) {
      actionToRedo.x = rectCopy.x;
      actionToRedo.y = rectCopy.y;
      actionToRedo.width = rectCopy.width;
      actionToRedo.height = rectCopy.height;
      actionToRedo.categoryId = rectCopy.categoryId;
      this.rectangleAnnotationData.addHistory(actionToRedo);
      this.undoTurn.push(AnnotationType.Rectangles);
    }
  }

  redoMoveRectangle(actionToRedo: RectangleHistory) {
    const rectCopy = this.getRectCopy(actionToRedo);
    this.undoMoveRectangle(actionToRedo);
    if (rectCopy) {
      actionToRedo.x = rectCopy.x;
      actionToRedo.y = rectCopy.y;
      actionToRedo.categoryId = rectCopy.categoryId;
      this.rectangleAnnotationData.addHistory(actionToRedo);
      this.undoTurn.push(AnnotationType.Rectangles);
    }
  }

  deleteSelection() {
    if (this.isRectangleSelected()) {
      const rect = this.rectangleAnnotationData.rectangles.find(
        r => String(r.id) == this.selectedRectangleId
      );

      if (rect) {
        this.detachTransformer();
        const index = this.rectangleAnnotationData.rectangles.indexOf(rect);
        this.rectangleAnnotationData.rectangles.splice(index, 1);
        this.selectedRectangleId = "";
      }
    }
  }

  rectangleResizeBoundingBox(oldBoundBox: any, newBoundBox: any) {
    if (
      newBoundBox.x < 0 ||
      newBoundBox.y < 0 ||
      newBoundBox.x + newBoundBox.width > this.zoomManager.imageSize.width ||
      newBoundBox.y + newBoundBox.height > this.zoomManager.imageSize.height
    ) {
      return oldBoundBox;
    }
    return newBoundBox;
  }

  dragAnchorBoundaries(pos: any) {
    pos.x = Math.min(pos.x, this.zoomManager.imageSize.width);
    pos.x = Math.max(pos.x, 0);
    pos.y = Math.min(pos.y, this.zoomManager.imageSize.height);
    pos.y = Math.max(pos.y, 0);
    return pos;
  }

  zoom(event: any) {
    this.zoomManager.changeZoom(event, this.getMousePosition());

    // ensure that the image is positioned correctly after zooming out
    const group: any = this.$refs.group;
    group
      .getNode()
      .position(
        this.zoomManager.dragImageBoundaries(group.getNode().getPosition())
      );
  }

  toggleMode() {
    this.zoomManager.toggleMode();
  }

  zoomTransformPointSet(currentValue: number, index: number) {
    if (index % 2 == 0) {
      return this.zoomManager.zoomedPositionX(currentValue);
    } else {
      return this.zoomManager.zoomedPositionY(currentValue);
    }
  }

  dragImageBoundaries(pos: any) {
    return this.zoomManager.dragImageBoundaries(pos);
  }

  dragAnchor(action: PolygonHistory) {
    const polygon = this.polygonAnnotationData.polygons[action.polygonIndex];
    polygon.anchors[action.anchorIndex].x = action.x;
    polygon.anchors[action.anchorIndex].y = action.y;
    if (!polygon.emptyEdges()) {
      if (action.anchorIndex > 0) {
        polygon.edges[action.anchorIndex - 1].pointSet.second = {
          x: action.x,
          y: action.y
        };
      }
      if (polygon.edgesSize() > action.anchorIndex) {
        polygon.edges[action.anchorIndex].pointSet.first = {
          x: action.x,
          y: action.y
        };
      }
      if (action.anchorIndex === 0 && polygon.isPolygonFinished) {
        const lastEdgeIndex = polygon.edgesSize() - 1;
        polygon.edges[lastEdgeIndex].pointSet.second = {
          x: action.x,
          y: action.y
        };
      }
    }
  }

  getAnnotations() {
    this.$emit("saveStart");
    this.getPolygonAnnotations().finally(() => {
      this.getRectangleAnnotations().finally(() => {
        this.$emit("saveEnd");
      });
    });
  }

  getPolygonAnnotations() {
    return annotationService
      .getAnnotationsWithPointsForPhotoAndTask(this.imageId, this.taskId)
      .then(response => {
        const polygonAnnotations = response.data.filter(
          (annotation: AnnotationWithPointsEntity) =>
            this.polygonCategoryIds.includes(annotation.category)
        );
        this.polygonAnnotationData.setPolygonsFromAnnotations(
          polygonAnnotations
        );
      });
  }

  async getRectangleAnnotations() {
    return annotationService
      .getAnnotationsWithPointsForPhotoAndTask(this.imageId, this.taskId)
      .then(response => {
        const rectangleAnnotations = response.data.filter(
          (annotation: AnnotationWithPointsEntity) =>
            this.rectangleCategoryIds.includes(annotation.category)
        );
        this.rectangleAnnotationData.setRectanglesFromAnnotations(
          rectangleAnnotations,
          this.categoryColors
        );
      });
  }

  async save() {
    this.$emit("saveStart");
    await this.savePolygons();
    await this.saveRectangles();
    this.$emit("saveEnd");
  }

  async savePolygons() {
    const annotations = this.polygonAnnotationData.mapPolygonsToAnnotations(
      this.taskId,
      this.imageId
    );

    const annotationIds = annotations.map(annotation => annotation.id);

    // We want to delete annotations that were saved in database but later removed on frontend
    const savedAnnotations: AnnotationEntity[] = await annotationService
      .getAnnotationsForPhotoAndTask(this.imageId, this.taskId)
      .then(response => {
        return response.data.filter((annotation: AnnotationEntity) =>
          this.polygonCategoryIds.includes(annotation.category)
        );
      });

    await savedAnnotations
      .filter(annotation => {
        return !annotationIds.includes(annotation.id);
      })
      .forEach(annotation => {
        annotationService.deleteAnnotationPoints(annotation.id);
        annotationService.deleteVisualAnnotation(annotation.id);
      });

    // After removing deleted annotations, add newly created and update edited
    await annotations.forEach(annotation => {
      annotation.save();
    });

    // If we created new annotations, it is important to update current ids of stored rectangles
    this.polygonAnnotationData.updatePolygonIds(annotations);
  }

  async saveRectangles() {
    const annotations = this.rectangleAnnotationData.mapRectanglesToAnnotations(
      this.taskId,
      this.imageId
    );

    const annotationIds = annotations.map(annotation => annotation.id);

    // We want to delete annotations that were saved in database but later removed on frontend
    const savedAnnotations: AnnotationEntity[] = await annotationService
      .getAnnotationsForPhotoAndTask(this.imageId, this.taskId)
      .then(response => {
        return response.data.filter((annotation: AnnotationEntity) =>
          this.rectangleCategoryIds.includes(annotation.category)
        );
      });

    await savedAnnotations
      .filter(annotation => {
        return !annotationIds.includes(annotation.id);
      })
      .forEach(annotation => {
        annotationService.deleteAnnotationPoints(annotation.id);
        annotationService.deleteVisualAnnotation(annotation.id);
      });

    // After removing deleted annotations, add newly created and update edited
    await annotations.forEach(annotation => {
      annotation.save();
    });

    // If we created new annotations, it is important to update current ids of stored rectangles
    this.rectangleAnnotationData.updateRectangleIds(annotations);

    this.detachTransformer();
  }

  hotkeyHandler(event: any) {
    switch (event.srcKey) {
      case "save":
        this.save();
        break;
      case "clear":
        this.clear();
        break;
      case "undo":
        this.undo();
        break;
      case "redo":
        this.redo();
        break;
      case "move":
        this.zoomManager.toggleMode();
        break;
      case "delete":
        this.deleteSelection();
        break;
    }
  }
}
