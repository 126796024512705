import { AnnotationEntity } from "@/entities/Annotation.entity";
import { AnnotationPointEntity } from "@/entities/AnnotationPoint.entity";
import { axiosBase } from "@/api/axios-base";
import { VisualExportEntity } from "@/entities/VisualExport.entity";
import store from "@/store";
import { Point } from "@/models/Point";
import { AnnotationWithPointsEntity } from "@/entities/AnnotationWithPoints.entity";

class AnnotationService {
  // Many annotations for one task and photo are possible
  getAnnotationsForPhotoAndTask(photoId: number, taskId: number) {
    return axiosBase.get<AnnotationEntity[]>(
      `/task-photo-annotations/${photoId}/${taskId}/`,
      {
        headers: { Authorization: `Bearer ${store.state.accessToken}` }
      }
    );
  }

  getAnnotationsWithPointsForPhotoAndTask(photoId: number, taskId: number) {
    return axiosBase.get<AnnotationWithPointsEntity[]>(
      `/task-photo-annotations-with-points/${taskId}/${photoId}/`,
      {
        headers: { Authorization: `Bearer ${store.state.accessToken}` }
      }
    );
  }

  getAnnotationPoints(id: number) {
    return axiosBase.get<AnnotationPointEntity[]>(`/annotation-points/${id}/`, {
      headers: { Authorization: `Bearer ${store.state.accessToken}` }
    });
  }

  addVisualAnnotation(
    photoId: number,
    taskId: number,
    categoryId: number,
    isFinished: boolean
  ) {
    const data = new FormData();
    data.append("visual_task", String(taskId));
    data.append("photo", String(photoId));
    data.append("category", String(categoryId));
    data.append("is_finished", String(isFinished));

    return axiosBase.post(`/visual-annotation/`, data, {
      headers: { Authorization: `Bearer ${store.state.accessToken}` }
    });
  }

  updateVisualAnnotation(
    id: number,
    taskId: number,
    photoId: number,
    categoryId: number,
    isFinished: boolean
  ) {
    const data = new FormData();
    data.append("visual_task", String(taskId));
    data.append("photo", String(photoId));
    data.append("category", String(categoryId));
    data.append("is_finished", isFinished ? "True" : "False");

    return axiosBase.put(`/visual-annotation/${id}/`, data, {
      headers: { Authorization: `Bearer ${store.state.accessToken}` }
    });
  }

  updateVisualAnnotationGroup(
    taskId: number,
    photoId: number,
    comment: string,
    isBad: boolean
  ) {
    const data = new FormData();
    data.append("comment", comment);
    data.append("is_bad", isBad ? "True" : "False");

    return axiosBase.post(
      `/visual-annotation-group/${taskId}/${photoId}/`,
      data,
      {
        headers: { Authorization: `Bearer ${store.state.accessToken}` }
      }
    );
  }

  getVisualAnnotationGroup(taskId: number, photoId: number) {
    return axiosBase.get(`/visual-annotation-group/${taskId}/${photoId}/`, {
      headers: { Authorization: `Bearer ${store.state.accessToken}` }
    });
  }

  deleteVisualAnnotation(id: number) {
    return axiosBase.delete(`/visual-annotation/${id}/`, {
      headers: { Authorization: `Bearer ${store.state.accessToken}` }
    });
  }

  // updates specific annotation with new set of points
  updateAnnotationPoints(annotationId: number, points: Array<Point>) {
    return axiosBase.post(
      `/visual-annotation-points/${annotationId}/`,
      points,
      {
        headers: { Authorization: `Bearer ${store.state.accessToken}` }
      }
    );
  }

  deleteAnnotationPoints(annotationId: number) {
    return axiosBase.delete(`/visual-annotation-points/${annotationId}/`, {
      headers: { Authorization: `Bearer ${store.state.accessToken}` }
    });
  }

  /**
   * Export
   */
  getExportAnnotationDataForTask(id: number) {
    return axiosBase.get<VisualExportEntity[]>(`/export-visual-task/${id}/`, {
      headers: { Authorization: `Bearer ${store.state.accessToken}` }
    });
  }
}

// Export a singleton instance in the global namespace
export const annotationService = new AnnotationService();
