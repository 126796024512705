import { Point } from "@/models/Point";
import { annotationService } from "@/services/annotation.service";
import { AnnotationType } from "@/models/enums/AnnotationType";

export class Annotation {
  constructor(
    public id: number,
    public taskId: number,
    public photoId: number,
    public categoryId: number,
    public isFinished: boolean,
    public type: AnnotationType,
    public points: Point[]
  ) {}

  private async saveAndGetId(): Promise<number> {
    let createdAnnotationId = -1;
    await annotationService
      .addVisualAnnotation(
        this.photoId,
        this.taskId,
        this.categoryId,
        this.isFinished
      )
      .then(response => {
        createdAnnotationId = response.data.id;
      });

    return createdAnnotationId;
  }

  private updateExistingAnnotation() {
    annotationService
      .updateVisualAnnotation(
        this.id,
        this.taskId,
        this.photoId,
        this.categoryId,
        this.isFinished
      )
      .then(response => {});
  }

  private updateAnnotationPoints() {
    annotationService
      .updateAnnotationPoints(this.id, this.points)
      .then(response => {});
  }

  public async save() {
    // if annotation was not saved before
    if (this.id < 0) {
      this.id = await this.saveAndGetId();
    } else {
      await this.updateExistingAnnotation();
    }

    await this.updateAnnotationPoints();
  }
}
